import RegisterCardBody from "./RegisterCardBody";
import React, {useState} from "react";
import {Link} from "react-router-dom";

export function Register1(){
    const [chats, setChats] = useState([
        "Spiele weiter oder verlasse mich."
    ]);
    return(
        <RegisterCardBody title={"Dies ist Passiony"} chats={chats}>
            <Link to={"/register/1"}><button className="btn btn-success forward">Weiter</button></Link>
        </RegisterCardBody>
    )
}

export function Register2(){
    const [chats, setChats] = useState([
        "Bist du von deinem gegenwärtigen Leben gelangweilt und suchst ein neues Abenteuer mit persönlichen Herausforderungen?",
        "Möchtest du deine Stärken nutzen, um dein Potenzial mehr auszuleben und somit ein spannendes Leben voller Möglichkeiten wählen zu können?",
        "Dann trainiere hier dein Mindset, erweitere dein Netzwerk und eigne dir ein unschlagbares Know-How an."
    ]);
    const [chatIndex, setChatIndex] = useState(1);
    return(
        <RegisterCardBody title={"Was Dich hier erwartet?"} index={chatIndex} chats={chats}>
            <Link to="/register/0"><button className="btn btn-danger back" style={{marginRight: "2%"}} >Zurück</button></Link>
            <button className="btn btn-success forward" onClick={() => setChatIndex(prev => prev+1)}>Weiter</button>
        </RegisterCardBody>
    )
}

export function Register3(){
    const [chats, setChats] = useState([
        "Wenn du lernst mich als dein persönliches Tool für deine Selbstverwirklichung anzusehen, wird sich dein Leben schon in wenigen Tagen, Wochen und Monaten zum ultimativen Erfolgskonzept verwandeln."
    ]);
    return(
        <RegisterCardBody title={"Mein Motiv ist dein Fortschritt 😉 ..."} chats={chats}>
            <Link to="/register/1"><button className="btn btn-danger back" style={{marginRight: "2%"}} >Zurück</button></Link>
            <Link to="/register/3"><button className="btn btn-success forward">Weiter</button></Link>
        </RegisterCardBody>
    )
}

export function Register4(){
    const [chats, setChats] = useState([
        "Brennst du bereits für deinen Traum oder möchtest deine Leidenschaft endlich entdecken, besser noch ausleben & deine Freunde und Familie daran teilhaben lassen?"
    ]);
    return(
        <RegisterCardBody title={"Was ist dein Sinn im Leben?"} chats={chats}>
            <Link to="/register/2"><button className="btn btn-danger back" style={{marginRight: "2%"}} >Zurück</button></Link>
            <Link to="/register/4"><button className="btn btn-success forward">Weiter</button></Link>
        </RegisterCardBody>
    )
}

export function Register5(){
    const [chats, setChats] = useState([
        "Dann biete ich dir an dieser Stelle die exklusive Möglichkeit an, dich auf deinem persönlichen Abenteuer durch Passiony zu begleiten."
    ]);
    return(
        <RegisterCardBody title={"Entscheide dich für den Erfolg!"} chats={chats}>
            <Link to="/register/3"><button className="btn btn-danger back" style={{marginRight: "2%"}} >Zurück</button></Link>
            <Link to="/register/5"><button className="btn btn-success forward">Weiter</button></Link>
        </RegisterCardBody>
    )
}

export function Register6(){
    const [chats, setChats] = useState([
        "Spiele weiter oder verlasse mich."
    ]);
    return(
        <RegisterCardBody title={"Der Weg ist das Ziel!"} chats={chats}>
            <Link to="/register/4"><button className="btn btn-danger back" style={{marginRight: "2%"}} >Zurück</button></Link>
            <Link to="/register/6"><button className="btn btn-success forward">Weiter</button></Link>
        </RegisterCardBody>
    )
}
